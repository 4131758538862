import { useEffect } from 'react';
import MetricCard from './MetricCard';
import SummaryRestService from '../services/SummaryRestService';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import ChurnIcon from '../../../assets/images/png/churn.png';
import GroupIcon from '../../../assets/images/png/group2.png';
import RepoIcon from '../../../assets/images/png/repository.png';
import PRIcon from '../../../assets/images/png/pull-request.png';
import CommitIcon from '../../../assets/images/png/commit.png';

const MetricCardContainer = () => {
  const { data: workspace, timeline } = useAppSelector((store) => store.workspace.selected);
  const repositoryIds = workspace.repositoryIds;
  const startDate = timeline.startDate;
  const endDate = timeline.endDate;

  const dispatch = useAppDispatch();

  const { value: reposAdded, isFetching: isFetchingReposAdded } = useAppSelector(
    (store) => store.summary.addedRepos,
  );
  const { value: acSum, isFetching: isFetchingAcSum } = useAppSelector(
    (store) => store.summary.activeContributorSum,
  );
  const { value: commitSum, isFetching: isFetchingCommitSum } = useAppSelector(
    (store) => store.summary.commitSum,
  );
  const { value: churnSum, isFetching: isFetchingChurnSum } = useAppSelector(
    (store) => store.summary.churnSum,
  );
  const { value: prSum, isFetching: isFetchingPrSum } = useAppSelector(
    (store) => store.summary.prSum,
  );

  const { value: prevReposAdded } = useAppSelector((store) => store.summary.prevAddedRepos);
  const { value: prevAcSum } = useAppSelector((store) => store.summary.prevActiveContributorSum);
  const { value: prevCommitSum } = useAppSelector((store) => store.summary.prevCommitSum);
  const { value: prevChurnSum } = useAppSelector((store) => store.summary.prevChurnSum);
  const { value: prevPrSum } = useAppSelector((store) => store.summary.prevPrSum);

  useEffect(() => {
    if (repositoryIds.length > 0 && startDate !== '' && endDate !== '') {
      dispatch(SummaryRestService.fetchRepositoriesAdded({  startDate, endDate, repositoryIds }));
      dispatch(SummaryRestService.fetchActiveContributorSum({ startDate, endDate, repositoryIds }));
      dispatch(SummaryRestService.fetchCommitSum({ startDate, endDate, repositoryIds }));
      dispatch(SummaryRestService.fetchChurnSum({ startDate, endDate, repositoryIds }));
      dispatch(SummaryRestService.fetchPrSum({ startDate, endDate, repositoryIds }));

      if (startDate && endDate) {

        const prevStartDate = new Date(
            new Date(startDate).getTime() -
            (new Date(endDate).getTime() - new Date(startDate).getTime()),
          ).toISOString().split('T')[0]
        const prevEndDate = startDate;

        dispatch(
          SummaryRestService.fetchPrevRepositoriesAdded({ 
            startDate: prevStartDate,
            endDate: prevEndDate,
            repositoryIds 
          }),
        );
        dispatch(
          SummaryRestService.fetchPrevActiveContributorSum({
            startDate: prevStartDate,
            endDate: prevEndDate,
            repositoryIds 
          }),
        );
        dispatch(SummaryRestService.fetchPrevCommitSum({ 
          startDate: prevStartDate,
          endDate: prevEndDate,
          repositoryIds 
        }));
        dispatch(SummaryRestService.fetchPrevChurnSum({
          startDate: prevStartDate,
          endDate: prevEndDate,
          repositoryIds 
        }));
        dispatch(SummaryRestService.fetchPrevPrSum({
          startDate: prevStartDate,
          endDate: prevEndDate,
          repositoryIds 
        }));
      }
    }
  }, [dispatch, repositoryIds, startDate, endDate]);

  const calculateChange = (current: number, previous: number) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    if (isNaN(current) || isNaN(previous)) return 0;
    return ((current - previous) / previous) * 100;
  };

  const changes = {
    churnSumChange: calculateChange(churnSum ?? 0, prevChurnSum ?? 0),
    acSumChange: calculateChange(acSum ?? 0, prevAcSum ?? 0),
    reposAddedChange: calculateChange(reposAdded ?? 0, prevReposAdded ?? 0),
    commitSumChange: calculateChange(commitSum ?? 0, prevCommitSum ?? 0),
    prSumChange: calculateChange(prSum ?? 0, prevPrSum ?? 0),
  };

  return (
    <>
      <div className='row justify-content-around mb-5'>
        <div className='col-sm-12 col-md-2'>
          <MetricCard
            iconValue={ChurnIcon}
            title='Code churn (LOC)'
            metricValue={churnSum ?? 0}
            change={changes?.churnSumChange}
            cardColor='#FFF4DE'
            isLoading={isFetchingChurnSum}
          />
        </div>

        <div className='col-sm-12 col-md-2'>
          <MetricCard
            iconValue={GroupIcon}
            title='Active contributors'
            metricValue={acSum ?? 0}
            change={changes?.acSumChange}
            cardColor='#E3FFDE'
            isLoading={isFetchingAcSum}
          />
        </div>

        <div className='col-sm-12 col-md-2'>
          <MetricCard
            iconValue={RepoIcon}
            title='Repositories'
            // metricValue={reposAdded ?? 0}
            metricValue={repositoryIds.length}
            // change={changes?.reposAddedChange}
            cardColor='#DDF9F6'
            isLoading={isFetchingReposAdded}
          />
        </div>

        <div className='col-sm-12 col-md-2'>
          <MetricCard
            iconValue={PRIcon}
            title='Active PRs'
            metricValue={reposAdded ?? 0}
            change={changes?.prSumChange}
            cardColor='#EADDCA'
            isLoading={isFetchingPrSum}
          />
        </div>

        <div className='col-sm-12 col-md-2'>
          <MetricCard
            iconValue={CommitIcon}
            title='Total commits'
            metricValue={commitSum ?? 0}
            change={changes?.commitSumChange}
            cardColor='#E7DEFF'
            isLoading={isFetchingCommitSum}
          />
        </div>
      </div>
    </>
  );
};

export default MetricCardContainer;
