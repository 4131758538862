import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../widgets/Card';
import { useFormik } from 'formik';
import FormGroup from '../../widgets/forms/FormGroup';
import FormGroupWrapper from '../../widgets/forms/FormGroupWrapper';
import Input from '../../widgets/forms/Input';
import Select from '../../widgets/forms/Select';
import Button from '../../widgets/Button';
import * as Yup from 'yup';
import WorkspaceRestService from './services/WorkspaceRestService';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { validateResponse } from '../../utils/ResponseHelpers';
import { toast } from 'react-toastify';

const CreateWorkspaceSchema = Yup.object().shape({
  name: Yup.string().required('Workspace name is required'),
  integration: Yup.string().required('Integration is required'),
});

const CreateWorkspace = () => {
  const dispatch = useAppDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      integration: '',
    },
    validationSchema: CreateWorkspaceSchema,
    validateOnChange: false,
    onSubmit: (values: { name: string; integration: string }) => {
      dispatch(WorkspaceRestService.createWorkspace(values)).then((response) => {
        if (!validateResponse(response, 'Failed to create workspace')) return;

        toast.success("I've created workspace for you!");
        dispatch(WorkspaceRestService.fetchWorkspaces());
      });
    },
  });

  return (
    <Card stretch>
      <CardHeader className='p-2 card-header integration-header'>
        <CardLabel className='w-100 d-flex justify-content-center'>
          <CardTitle tag='h4' className='h5'>
            {'Create new workspace'}
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='d-flex align-items-center justify-content-center'>
        <form className='row w-100'>
          <div className='col-8'>
            <div>
              <FormGroupWrapper isError={!!formik.errors.name} className='row w-100'>
                <FormGroup id='name' isFloating label='Workspace name' className={''}>
                  <Input
                    autoComplete='name'
                    value={formik.values.name}
                    isTouched={formik.touched.name}
                    invalidFeedback={formik.errors.name}
                    isValid={formik.isValid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => {
                      formik.setErrors({});
                    }}
                  />
                </FormGroup>
              </FormGroupWrapper>
            </div>
            <div className='row'>
              <FormGroupWrapper isError={!!formik.errors.integration} className='row w-100'>
                <FormGroup id='source' isFloating label='Select Integration' className={''}>
                  <Select
                    id='integration'
                    name='integration'
                    ariaLabel=''
                    value={formik.values.integration}
                    isTouched={formik.touched.integration}
                    invalidFeedback={formik.errors.integration}
                    isValid={formik.isValid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => {
                      formik.setErrors({});
                    }}
                    list={[
                      {
                        text: 'GitHub',
                        value: 'github',
                      },
                    ]}
                  />
                </FormGroup>
              </FormGroupWrapper>
            </div>
          </div>
          <div className='col-4 m-0'>
            <Button
              buttonType='primary-btn'
              size='lg'
              className='w-100'
              icon='AddCircle'
              onClick={formik.handleSubmit}>
              Create
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default CreateWorkspace;
